const theme = {
    BASE_FONT_FAMILY: 'IBM Plex Mono, sans-serif',
    BASE_FONT_SIZE: '15px',
    BASE_LINE_HEIGHT: 1.2,

    BASE_BACKGROUND_COLOR: 'transparent',
    BASE_COLOR: '#bbb',

    OBJECT_PREVIEW_ARRAY_MAX_PROPERTIES: 10,
    OBJECT_PREVIEW_OBJECT_MAX_PROPERTIES: 5,
    OBJECT_NAME_COLOR: '#444',
    OBJECT_VALUE_NULL_COLOR: '#64b5f6',
    OBJECT_VALUE_UNDEFINED_COLOR: 'rgb(128, 128, 128)',
    OBJECT_VALUE_REGEXP_COLOR: 'green',
    OBJECT_VALUE_STRING_COLOR: '#0069c0',
    OBJECT_VALUE_SYMBOL_COLOR: 'rgb(196, 26, 22)',
    OBJECT_VALUE_NUMBER_COLOR: '#0a0',
    OBJECT_VALUE_BOOLEAN_COLOR: '#a00',
    OBJECT_VALUE_FUNCTION_PREFIX_COLOR: '#aaa',

    HTML_TAG_COLOR: 'rgb(168, 148, 166)',
    HTML_TAGNAME_COLOR: 'rgb(136, 18, 128)',
    HTML_TAGNAME_TEXT_TRANSFORM: 'lowercase',
    HTML_ATTRIBUTE_NAME_COLOR: 'rgb(153, 69, 0)',
    HTML_ATTRIBUTE_VALUE_COLOR: 'rgb(26, 26, 166)',
    HTML_COMMENT_COLOR: 'rgb(35, 110, 37)',
    HTML_DOCTYPE_COLOR: 'rgb(192, 192, 192)',

    ARROW_COLOR: '#6e6e6e',
    ARROW_MARGIN_RIGHT: 8,
    ARROW_FONT_SIZE: 10,
    ARROW_ANIMATION_DURATION: '0',

    TREENODE_FONT_FAMILY: 'IBM Plex Mono, sans-serif',
    TREENODE_FONT_SIZE: '13px',
    TREENODE_LINE_HEIGHT: 1.2,
    TREENODE_PADDING_LEFT: 10,

    TABLE_BORDER_COLOR: '#888',
    TABLE_TH_BACKGROUND_COLOR: 'white',
    TABLE_TH_HOVER_COLOR: 'hsla(0, 0%, 90%, 1)',
    TABLE_SORT_ICON_COLOR: '#0089e0',
    TABLE_DATA_BACKGROUND_IMAGE:
        ' linear-gradient(to bottom, white, white 50%, rgb(234, 243, 243) 50%, rgb(234, 243, 243))',
    TABLE_DATA_BACKGROUND_SIZE: '100px 32px',
};

export default theme;